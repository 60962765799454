@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --white: 255, 255, 255;
    --white-d: 248, 248, 248;
    --black-l: 180, 176, 176;
    --black-p: 59, 59, 59;
    --blue-l: 225, 237, 255;
    --blue-p: 71, 145, 255;
    --red-p: 255, 35, 102;
    --red-l: 255, 218, 229;
    --purple-l: 240, 232, 250;
    --purple-p: 108, 24, 164;
    --purple-d: 73, 22, 147;
    --green-l: 212, 244, 232;
    --green-p: 2, 188, 119;
    --yellow-p: 252, 200, 41;
    --yellow-l: 255, 246, 221;
  }

  .dark {
    --primary-color: 132, 29, 57;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  margin: 0;
  font-size: 0.8rem;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden !important;
}

.gradient-p {
  background: rgba(56, 39, 164, 1);
  /*
  background: linear-gradient(
    90deg,
    rgba(108, 24, 164, 0.9) 10%,
    rgba(108, 24, 164, 1) 30%,
    rgba(56, 39, 180, 1) 60%
  );*/
}

/* hide default date*/
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  /* display: none;
    -webkit-appearance: none;*/
  font-size: 18px;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  overflow: hidden !important;
}

.bg-gradient {
  background: transparent linear-gradient(90deg, #3827b4 0%, #6c18a4 100%) 0% 0%
    no-repeat padding-box !important;
}

.footer {
  background: #491493 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 14px #00000017;
}

.custom-btn {
  background: transparent linear-gradient(90deg, #4a1192 0%, #2cd5c4 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
}

.table-row {
  @apply odd:bg-white even:bg-gray-100;
}

.table-cell {
  @apply px-3 py-4 first:rounded-l-md last:rounded-r-md;
}
